import axios from "axios";
let apiGateway = "https://zee5-4g6mkykelq-uc.a.run.app";
const instance = axios.create({
  baseURL: apiGateway,
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});
export async function register(type, value, fname, lname) {
  try {
    var data = JSON.stringify({
      data: {
        type: type,
        value: value,
        partner_uuid: value,
        fname: fname,
        lname: lname,
        notification: "1",
      },
      path: "/user/register",
      register: true,
    });
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    let result = await instance.post(`/api/v1/zee5/register`, data, {
      headers,
    });

    return result;
  } catch (Err) {
    console.log("Error in register only", Err);
    throw new Error(Err);
  }
}

export async function registerAndSubscribe(
  type,
  value,
  fname,
  lname,
  userid,
  starhubAccessToken,
  refreshToken,
  permissions,
  above21
) {
  try {
    var data = {
      data: {
        type: type,
        value: value,
        partner_uuid: value,
        fname: fname,
        lname: lname,
        notification: "1",
      },
      path: "/user/subscription/attach",
      userId: userid,
      status: true,
      starhubAccessToken: starhubAccessToken,
      refreshToken: refreshToken,
      permissions: permissions,
      above21 : above21,
      
    };
    if (!data.data.fname) delete data.data.fname;
    if (!data.data.lname) delete data.data.lname;
    data = JSON.stringify(data);
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    // console.log("above21" , above21);
    let res = await instance.post("api/v1/zee5/registerAndSubscribe", data, {
      headers,
    });
    return res;
  } catch (Err) {
    throw new Error(Err);
  }
}

export async function loginAndSubscribe(
  type,
  value,
  userid,
  starhubAccessToken,
  refreshToken,
  permissions,
  above21
) {
  try {
    var data = JSON.stringify({
      data: {
        type: type,
        value: value,
      },
      device_id: "hUUcpHklMXZB7b2l9Hpc000000000000",
      esk: "aFVVY3BIa2xNWFpCN2IybDlIcGMwMDAwMDAwMDAwMDBfX1BMdHp2Uk9BWUZtTWZIak9QdXZmckVmVWM2U",
      path: "/user/subscription/attach",
      userId: userid,
      starhubAccessToken: starhubAccessToken,
      status: true,
      refreshToken: refreshToken,
      permissions: permissions,
      above21 : above21
    });
    //console.log("login", data);
    let headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    let res = await instance.post("api/v1/zee5/loginAndSubscribe", data, {
      headers,
    });
    return res;
  } catch (Err) {
    throw new Error(Err);
  }
}
