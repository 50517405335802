import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notify } from "../utils/notify";
import { register, registerAndSubscribe } from "../utils/api";
import WithSubscription from "./withSubscription";
import WithoutSubscription from "./WithoutSubscriptionFreePlan";
import WithoutSubscriptionAlternativeEmail from "./WithoutSubscriptionAlternativeEmail";
import AlreadySubscribed from "./AlreadySubscribed";

let value;
let flag;
function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return "email";
  }
  return "mobile";
}
let handleOnChange = async (event, props) => {
  event.preventDefault();
  let { userId, status, starhubAccessToken, refreshToken } = props.data.data;
  let email = document.querySelector("#registerEmail").value;
  let registerFname = document.querySelector("#registerFname").value;
  let registerLnameValue = document.querySelector("#registerLname").value;
  let permissions = document.querySelector(".allow-checkbox").checked;
  let above21 = document.querySelector(".checkbox-above21").checked;

  let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email === "") {
    value = "Please enter your Email ID";
    notify(value);
    flag = false;
    console.log("email field empty--", flag);
  } else if (!mailFormat.test(email)) {
    value = "Email ID is NOT valid.";
    notify(value);
    flag = false;
  } else if (!permissions) {
    notify("Please agree to the Terms of Use and Privacy Policy.");
  }else if (!above21) {
    notify("Please confirm if you are above 21 years.");
  } 
  else if (mailFormat.test(email)) {
    value = "Email ID is valid";
    let type = ValidateEmail(email);
    if (status) {
      try {
        let res = await registerAndSubscribe(
          type,
          email,
          registerFname,
          registerLnameValue,
          userId,
          starhubAccessToken,
          refreshToken,
          permissions,
          above21
        );
        if (res?.data?.status === 403)
          ReactDOM.render(
            <AlreadySubscribed />,
            document.getElementById("root")
          );
        else if (res?.data?.status === 404) notify("Something Went very wrong");
        else if (res?.data?.data?.startDate)
          ReactDOM.render(
            <WithSubscription />,
            document.getElementById("root")
          );
        else
          ReactDOM.render(
            <WithoutSubscriptionAlternativeEmail />,
            document.getElementById("root")
          );
      } catch (error) {
        value = error.mes;
        notify(error?.message ? error?.message : "something went wrong");
      }
    } else {
      try {
        let res = await register(
          type,
          email,
          registerFname,
          registerLnameValue
        );
        ReactDOM.render(
          <WithoutSubscription />,
          document.getElementById("root")
        );
      } catch (error) {
        value = error.mes;
        notify(error?.message ? error?.message : "something went wrong");
      }
    }
  }
};

function buttonToColorRegisterPage() {
  const registerEmailValue = document.querySelector("#registerEmail").value;
  const permissions = document.querySelector(".allow-checkbox").checked;
  const above21 = document.querySelector(".checkbox-above21").checked;

  const registerBtn = document.querySelector("#registerBtn");
  console.log("registercolor" , registerEmailValue , permissions);
  if (
    registerEmailValue !== "" &&
    permissions && above21
  ) {
    registerBtn.style.backgroundColor = "#8230C6";
    registerBtn.style.color = "#ffffff";
  } else if (
    registerEmailValue === "" ||
    !permissions || !above21
  ) {
    registerBtn.style.backgroundColor = "transparent";
    registerBtn.style.color = "#686b6c";
  }
}
function Register(props) {
  return (
    <div className="outsideRegister">
      <div className="register">
      <div className="twologos">
          <img src="./images/starhubLogo.svg" className="starhub-logo" alt="" />
          {/* <img src="./images/Vector80.svg" className="vector" alt="" /> */}
          <div className="inbetween-line"></div>
          <img src="./images/Zee5Logo.svg" className="zee5-logo" alt="" />
        </div>

        <form action="" className="signup-form-register">
          <div className="form-body-register">
            <div className="text-register">
              <p>
              Create a ZEE5 account to continue enjoying
uninterrupted video and personalised experience
              </p>
            </div>

            <div className="wrapper fname">
              <div className="input-data">
                <input
                  type="text"
                  className="fnameField"
                  id="registerFname"
                  required
                  onInput={buttonToColorRegisterPage}
                />

                <div className="underline"></div>
                <label>Enter your First Name</label>
              </div>
            </div>

            <div className="wrapper lname">
              <div className="input-data">
                <input
                  type="text"
                  className="lnameField"
                  id="registerLname"
                  required
                  onInput={buttonToColorRegisterPage}
                />

                <div className="underline"></div>
                <label>Enter your Last Name</label>
              </div>
            </div>

            <div className="wrapper email">
              <div className="input-data">
                <input
                  type="text"
                  className="emailField"
                  id="registerEmail"
                  required
                  onInput={buttonToColorRegisterPage}
                />

                <div className="underline"></div>
                <label>Email ID*</label>
              </div>
            </div>
          </div>
          <div className="allow-access">
            <input
              type="checkbox"
              id="access"
              name="permissions"
              className="allow-checkbox"
              value="allow"
              onClick={buttonToColorRegisterPage}
            />
            <p for="permissions" className="permissions-text">
              {" "}
              * I agree to ZEE5's{" "}
              <a
                href="https://www.zee5.com/termsofuse"
                target="_blank"
                rel="noopener noreferrer"
                className="terms"
              >
                Terms of Use
              </a>
              {"  "}
              and{" "}
              <a
                href="https://www.zee5.com/privacypolicy"
                target="_blank"
                className="policies"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="allow-access">
            <input
              type="checkbox"
              id="access"
              name="permissions"
              className="checkbox-above21"
              value="allow"
              onClick={buttonToColorRegisterPage}
            />
            <p for="permissions" className="permissions-text">
              {" "}
              * I am above 21 years.
            </p>
          </div>
          <div className="form-footer-register">
            <input
              type="button"
              className="register-btn"
              id="registerBtn"
              value="Create a zee5 account"
              onClick={(e) => {
                console.log("eprops", props);
                handleOnChange(e, props);
              }}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Register;
