function SubscriptionStatusFalse(props) {
  console.log("prep", props.data);
  let { status } = props.data;
  console.log("status", status);

  return (
    <div className="outside-false-status">
      <div className="false-status">
      <div className="twologos">
          <img src="./images/starhubLogo.svg" className="starhub-logo" alt="" />
          {/* <img src="./images/Vector80.svg" className="vector" alt="" /> */}
          <div className="inbetween-line"></div>
          <img src="./images/Zee5Logo.svg" className="zee5-logo" alt="" />
        </div>

        <form action="" className="signup-false-status">
          <div className="text-false-status">
            <p className="false-header">
              {" "}
              You need an active Indian+ or ZEE5 Premium subscription (with StarHub) before you can link
              your StarHub account with ZEE5.
            </p>
            <p className="false-text">For more info, visit <a href="https://starhub.com/zee5-faq" target={"_blank"} className="false-link">starhub.com/zee5-faq</a></p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SubscriptionStatusFalse;
