import { BrowserRouter, Route, Routes } from "react-router-dom";
import StarhubConfirmation from "./components/StarhubConfirmation";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<StarhubConfirmation />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
