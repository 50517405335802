function WithSubscription() {
  return (
    <div className="outsideSubscribe">
      <div className="logoAndText">
        <img src="../images/Zee5Logo.svg" className="zee5-logo" alt="" />

        <div className="topText">
          <img className="green" src="../images/green.svg" alt="" />
          <p className="subscribe-msg">
            &nbsp; You have successfully linked your StarHub account with ZEE5.
          </p>
        </div>
        <div className="middleText">
      To start watching, go to <a href="https://www.zee5.com" className="zee5-link" target={"_blank"}> www.zee5.com </a> or download the ZEE5 app and
    log in with the email address you used for your ZEE5 account.
      </div>
      {/* <div className="footerBtn">
          <a className="footerapple" href="https://apps.apple.com/in/app/zee5-movies-web-series-shows/id743691886" target={"_blank"}>
             <img src="../images/apple.png" className="appleicon"></img>
             <div className="apple-text">
               <p className="donwloadonthe">Download on the</p>
               <p className="appstore"> App Store</p>
             </div>
          </a>
          <a className="footerapple" href="https://play.google.com/store/apps/details?id=com.graymatrix.did&hl=en_IN&gl=US" target={"_blank"}>
             <img src="../images/playstore.png" className="playicon"></img>
             <div className="apple-text">
               <p className="donwloadonthe">Get it on</p>
               <p className="appstore">Play Store</p>
             </div>
          </a>
      </div> */}
        <div className="footerBtn">
          <a className="footerapple" href="https://apps.apple.com/in/app/zee5-movies-web-series-shows/id743691886" target={"_blank"}>
             <img src="../images/app-store-badge.png" className="appleicon"></img>
             {/* <div className="apple-text">
               <p className="donwloadonthe">Download on the</p>
               <p className="appstore"> App Store</p>
             </div> */}
          </a>
          <a className="footerapple" href="https://play.google.com/store/apps/details?id=com.graymatrix.did&hl=en_IN&gl=US" target={"_blank"}>
             <img src="../images/google-play-badge (2).png" className="playicon"></img>
             {/* <div className="apple-text">
               <p className="donwloadonthe">Get it on</p>
               <p className="appstore">Play Store</p>
             </div> */}
          </a>
      </div>
      </div>
    
    </div>
  );
}

export default WithSubscription;
