import React from "react";

function WithoutSubscriptionAlternativeEmail() {
  return (
    <div className="outsideAlternateEmail">
      <div className="alternateEmail">
      <div className="twologos">
          <img src="./images/starhubLogo.svg" className="starhub-logo" alt="" />
          {/* <img src="./images/Vector80.svg" className="vector" alt="" /> */}
          <div className="inbetween-line"></div>
          <img src="./images/Zee5Logo.svg" className="zee5-logo" alt="" />
        </div>
        ​
        <form action="" className="signup-form-alternateEmail">
          <div className="form-body-alternateEmail">
            <div className="text-alternateEmail">
              <p>
              The email address already has an existing ZEE5 Premium subscription.
              </p>
              <h6 className="alternative-suggestion">To link your StarHub account with ZEE5, please create a new ZEE5 account using a different
email address.</h6>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
export default WithoutSubscriptionAlternativeEmail;
