import React from "react";
function WithoutSubscription() {

  return (
    <div className="outsideSubscribe">
      <div className="logoAndText">
        <img className="zee5-logo" src="../images/Zee5Logo.svg" alt="" />

        <div className="topText">
         
          <img className="red" src="../images/red.png" alt="" />
          <p className="subscribe-msg">
            &nbsp; You have not subscribed to Zee5. Please subscribe Premium
            plan to enjoy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default WithoutSubscription;
