import ReactDOM from "react-dom";
import Login from "./Login";
import Register from "./Register";

function DropdownSelection(props) {
  function loginBtn() {
    let radioNoBtn = document.getElementById("radioNoBtn");
    let radioYesBtn = document.getElementById("radioYesBtn");
    radioYesBtn.checked = true;
    radioNoBtn.checked = false;
    const subscriptionStatusBtn = document.querySelector(
      "#subscriptionStatusBtn"
    );
    subscriptionStatusBtn.value = "Log in to my ZEE5 account";
    subscriptionStatusBtn.style.backgroundColor = "#8230C6";
    subscriptionStatusBtn.style.color = "#ffffff";
    subscriptionStatusBtn.classList.remove("hidden");
    subscriptionStatusBtn.type = "button";
  }

  function registerBtn() {
    let radioYesBtn = document.getElementById("radioYesBtn");
    let radioNoBtn = document.getElementById("radioNoBtn");
    radioYesBtn.checked = false;
    radioNoBtn.checked = true;
    const subscriptionStatusBtn = document.querySelector(
      "#subscriptionStatusBtn"
    );
    subscriptionStatusBtn.value = "Create ZEE5 account";
    subscriptionStatusBtn.style.backgroundColor = "#8230C6";
    subscriptionStatusBtn.style.color = "#ffffff";
    subscriptionStatusBtn.classList.remove("hidden");
  }

  function renderUI(status, userId) {
    const value = document.querySelector('input[name="status"]:checked').value;
    if (value === "Yes") {
      ReactDOM.render(
        <Login data={props} />,
        document.getElementById("root")
      );
    } else if (value === "No") {
      ReactDOM.render(
        <Register data={props} />,
        document.getElementById("root")
      );
    }
  }

  return (
    <div className="outsideStatusPage">
      <div className="statusPage">
      <div className="twologos">
          <img src="./images/starhubLogo.svg" className="starhub-logo" alt="" />
          {/* <img src="./images/Vector80.svg" className="vector" alt="" /> */}
          <div className="inbetween-line"></div>
          <img src="./images/Zee5Logo.svg" className="zee5-logo" alt="" />
        </div>

        <form action="" className="signup-form-status">
          <div className="form-body-status">
            <div className="radioContainer">
              <label for="">Are you already a registered ZEE5 user?</label>
              <div>
                <input
                  type="radio"
                  className="radioYes"
                  id="radioYesBtn"
                  name="status"
                  value="Yes"
                  onClick={() => {
                    loginBtn();
                  }}
                />
                <label for="radioYesBtn">Yes</label>
              </div>

              <div>
                <input
                  type="radio"
                  className="radioNo"
                  id="radioNoBtn"
                  defaultChecked
                  name="status"
                  value="No"
                  onClick={() => {
                    registerBtn();
                  }}
                />
                <label for="radioNoBtn">No&nbsp; </label>
              </div>
            </div>
          </div>

          <div className="form-footer-status">
            <input
              type="button"
              className="btnStatus"
              id="subscriptionStatusBtn"
              value="Create ZEE5 account"
              onClick={(e) => renderUI()}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default DropdownSelection;
