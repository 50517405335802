import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import WithSubscription from "./withSubscription";
import "react-toastify/dist/ReactToastify.css";
import WithoutSubscription from "./WithoutSubscriptionFreePlan";
import { loginAndSubscribe } from "../utils/api";
import AlreadySubscribed from "./AlreadySubscribed";
import WithoutSubscriptionAlternativeEmail from "./WithoutSubscriptionAlternativeEmail";
import { notify } from "../utils/notify";
let value;
let flag;
function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return "email";
  }
  return "mobile";
}
let handleOnChange = async (event, props) => {
  event.preventDefault();
  let { userId, status, starhubAccessToken, refreshToken } = props.data.data;
  let email = document.querySelector("#loginEmail").value;
  let permissions = document.querySelector(".allow-checkbox").checked;
  let above21 = document.querySelector(".checkbox-above21").checked;
  let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email === "") {
    value = "Please enter your Email ID";
    notify(value);
    flag = false;
  } else if (!permissions) {
    notify("Please agree to the Terms of Use and Privacy Policy.");
  } else if (!above21) {
    notify("Please confirm if you are above 21 years.");
  } 
  else if (!mailFormat.test(email)) {
    value = "Email is NOT valid.";
    notify(value);
    flag = false;
  } else if (mailFormat.test(email)) {
    value = "Email ID is valid !";
    let type = ValidateEmail(email);
    if (status === "true") {
      try {
        let res = await loginAndSubscribe(
          type,
          email,
          userId,
          starhubAccessToken,
          refreshToken,
          permissions,
          above21
        );

        if (res?.data?.status === 403)
          ReactDOM.render(
            <AlreadySubscribed />,
            document.getElementById("root")
          );
        else if (res?.data?.status === 404) notify("No user account found. Please register a new account.");
        else if (res?.data?.data?.startDate)
          ReactDOM.render(
            <WithSubscription />,
            document.getElementById("root")
          );
        else
          ReactDOM.render(
            <WithoutSubscriptionAlternativeEmail />,
            document.getElementById("root")
          );
      } catch (error) {
        console.log(error, "error in register and subscribe zee5");
        value = error.mes;
        notify(error?.message ? error?.message : "something went wrong");
      }
    } else {
      ReactDOM.render(<WithoutSubscription />, document.getElementById("root"));
    }
  }
};

function Login(props) {
  function loginbuttonToColor() {
    const loginEmailValue = document.querySelector("#loginEmail").value;
    const loginBtn = document.querySelector("#loginBtn");
    let above21 = document.querySelector(".checkbox-above21").checked;
  const permissions = document.querySelector(".allow-checkbox").checked;
    if (loginEmailValue !== "" && above21 && permissions) {
      loginBtn.style.backgroundColor = "#8230C6";
      loginBtn.style.color = "#ffffff";
    } else if (loginEmailValue === "" || !above21 || !permissions) {
      loginBtn.style.backgroundColor = "transparent";
      loginBtn.style.color = "#686b6c";
    }
  }

  return (
    <div className="outsideLogin">
      <div className="login">
      <div className="twologos">
          <img src="./images/starhubLogo.svg" className="starhub-logo" alt="" />
          {/* <img src="./images/Vector80.svg" className="vector" alt="" /> */}
          <div className="inbetween-line"></div>
          <img src="./images/Zee5Logo.svg" className="zee5-logo" alt="" />
        </div>

        <form action="" className="signup-form-login">
          <div className="form-body-login">
            <div className="text-login">
              <p>
                Login to ZEE5 to continue enjoying uninterrupted video and personalised
                experience
              </p>
            </div>

            <div className="wrapper email">
              <div className="input-data">
                <input
                  type="text"
                  className="emailValue"
                  id="loginEmail"
                  required
                  onInput={loginbuttonToColor}
                />

                <div className="underline"></div>
                <label>Email ID*</label>
              </div>
            </div>
            <div className="allow-access">
              <input
                type="checkbox"
                id="access"
                name="permissions"
                className="allow-checkbox"
                value="allow"
                onClick={loginbuttonToColor}
              />
              <p for="permissions" className="permissions-text">
                {" "}
                * I agree to ZEE5's{" "}
                <a
                  href="https://www.zee5.com/termsofuse"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="terms"
                >
                  Terms of Use
                </a>
                {"  "}
                and{" "}
                <a
                  href="https://www.zee5.com/privacypolicy"
                  target="_blank"
                  className="policies"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="allow-access">
            <input
              type="checkbox"
              id="access"
              name="permissions"
              className="checkbox-above21"
              value="allow"
              onClick={loginbuttonToColor}
            />
            <p for="permissions" className="permissions-text">
              {" "}
              * I am above 21 years.
            </p>
          </div>

          </div>

          <div className="form-footer-login">
            <input
              type="button"
              className="btnlogin"
              id="loginBtn"
              value="Login"
              onClick={(e) => {
                handleOnChange(e, props);
              }}
            />
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Login;
