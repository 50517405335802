import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import DropdownSelection from "./DropdownSelection";
import SubscriptionStatusFalse from "./SubscriptionStatusFalse";
import AlreadySubscribed from "./AlreadySubscribed";
function StarhubConfirmation() {
  useEffect(() => {
    const handleMessage = (event) => {
      console.log("event" , event);
      if(event.data.data){
        // console.log(String(event.data.data));
        let data =  JSON.parse(event.data.data);
        console.log("datadata" , data);
        if (data.status) {
          window.removeEventListener("message", handleMessage);
          // window.close();
          if (data.taken) {

            ReactDOM.render(
              <AlreadySubscribed data={data} />,
              document.getElementById("root")
            );
          } else if (data.status === "true") {
            ReactDOM.render(
              <DropdownSelection data={data} />,
              document.getElementById("root")
            );
          } else {
            ReactDOM.render(
              <SubscriptionStatusFalse data={data} />,
              document.getElementById("root")
            );
          }
        }
      }
    };
    window.addEventListener("message", handleMessage);
  }, []);
  return (
    <div className="outsideAuthenticateButton">
      <div className="singleAuthenticateButton">
        <div className="twologos">
          <img src="./images/starhubLogo.svg" className="starhub-logo" alt="" />
          {/* <img src="./images/Vector80.svg" className="vector" alt="" /> */}
          <div className="inbetween-line"></div>
          <img src="./images/Zee5Logo.svg" className="zee5-logo" alt="" />
        </div>
        <form action="" className="signup-form-singleButton">
          <h5 className="confirmation-header">Activate your ZEE5 Premium
subscription from StarHub</h5>
<div className="confirmation-details">Log in with your Hub iD and create a ZEE5
account with your email address to start
enjoying ZEE5.</div>
          <div className="form-footer-authenticate">
            <input
              type="button"
              className="btnAuthenticate"
              value="Proceed"
              onClick={(event) => {
                //zee5 prod
                // window.open(
                //   "https://uat.auth.starhub.com/oauth/authorize?response_type=code&client_id=Zee5Bjd6ZKtE31KwDxM3jqssErOWG8EMXujMa24eZBu&redirect_uri=https://zee5-3ddd3qo4lq-uc.a.run.app/auth/callback"
                // );
                window.open(
                  "https://auth.starhub.com/oauth/authorize?response_type=code&client_id=Zee5EzsYpWrz0CNRfLE9ZIt9o1oeHfyXuJgs33npOQZ&redirect_uri=https://zee5-4g6mkykelq-uc.a.run.app/auth/callback"
                );
              
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default StarhubConfirmation;
